import React from 'react'
import { object } from 'prop-types'

import * as Styled from './styles/HcpdProviderListItem.styles'
import { TelehealthRow } from './styles/HcpdDetailPage.styles'
import {
  PresidentsCouncilBadge,
  ProfessionalMembershipBadge,
  TelehealthIcon,
} from '../Icons'
import CommittedToClearBadgeLink from './CommittedToClearBadgeLink'
import { hcpAddressToMapUri, withHttp } from './hcpdUtil'

import COREVITAS_BADGE from '../../images/corevitas.png'

// TODO: THis is too similar to version in HcpdDetailPage
const PracticeInfoColumn = ({ node }) => (
  <Styled.PracticeInfoColumn>
    <Styled.ColumnHeader>Practice</Styled.ColumnHeader>
    <Styled.PracticeContactDetailBlock>
      <h4>{node.clinic_name}</h4>
      <a
        href={hcpAddressToMapUri(node)}
        rel="noopener noreferrer"
        target="_blank"
      >
        <p>
          {node.address_line_1}
          {node.address_line_2 !== '' && `, ${node.address_line_2}`}
        </p>
        <p>
          {node.city}, {node.state} {node.zip}
        </p>
        <p>{node.country === 'US' ? 'United States' : node.country}</p>
      </a>
    </Styled.PracticeContactDetailBlock>
    <Styled.PracticeContactDetailBlock>
      {node.phone && (
        <p>
          <a href={`tel:+1${node.phone}`}>{node.phone}</a>
        </p>
      )}
      {node.url && (
        <p>
          <a
            href={withHttp(node.url)}
            rel="noopener noreferrer"
            target="_blank"
          >
            Visit Website
          </a>
        </p>
      )}
    </Styled.PracticeContactDetailBlock>
    {node.telehealth && (
      <TelehealthRow>
        <TelehealthIcon />
        <span>Telehealth Provider</span>
      </TelehealthRow>
    )}
  </Styled.PracticeInfoColumn>
)

const HcpdProviderListItem = ({ node }) => (
  <Styled.HcpdListItem>
    <Styled.ItemDivider />
    <Styled.ColumnHeader>Provider Name</Styled.ColumnHeader>
    <Styled.ProviderLink to={`/health-care-provider-directory/${node.slug}/`}>
      <h3>
        {node.first_name}
        {node.middle_name ? ` ${node.middle_name}` : ''} {node.last_name}
        {node.designation && `, ${node.designation}`}
      </h3>
    </Styled.ProviderLink>

    <Styled.PracticeDetailContainer>
      <PracticeInfoColumn node={node} />
      <Styled.CategoryColumn>
        <Styled.ColumnHeader>Specialty</Styled.ColumnHeader>
        <Styled.CategoryList>
          {node.specialties.map(specialty => (
            <li key={specialty}>{specialty}</li>
          ))}
        </Styled.CategoryList>
      </Styled.CategoryColumn>

      {node.treatments.length > 0 && (
        <Styled.CategoryColumn>
          <Styled.ColumnHeader>Treatments</Styled.ColumnHeader>
          <Styled.CategoryList>
            {node.treatments.map(treatment => (
              <li key={treatment}>{treatment}</li>
            ))}
          </Styled.CategoryList>
        </Styled.CategoryColumn>
      )}

      {node.payment_methods.length > 0 && (
        <Styled.CategoryColumn>
          <Styled.ColumnHeader>Payments</Styled.ColumnHeader>
          <Styled.CategoryList>
            {node.payment_methods.map(payment => (
              <li key={payment}>{payment}</li>
            ))}
          </Styled.CategoryList>
        </Styled.CategoryColumn>
      )}

      <Styled.CategoryColumn>
        {node.has_agreed_to_attestation && <CommittedToClearBadgeLink />}
        {node.is_in_presidents_council && <PresidentsCouncilBadge />}
        {node.is_in_mpm && <ProfessionalMembershipBadge />}
        {node.is_in_corrona_registry && (
          <img alt="CorEvitas Registry" src={COREVITAS_BADGE} />
        )}
      </Styled.CategoryColumn>
    </Styled.PracticeDetailContainer>
  </Styled.HcpdListItem>
)

HcpdProviderListItem.propTypes = {
  node: object,
}

export default HcpdProviderListItem
