import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

import HcpdProviderListItem from './HcpdProviderListItem'
import { RegionContainer, NoResultsMessage } from '../styles/Utility.styles'
import { haversineDistance } from '../../util/geolocation'
import PaginationControl from '../PaginatonControl'

const ITEMS_PER_PAGE = 25

const HCPD_PROVIDER_LIST_QUERY = graphql`
  {
    allHealthCareProvider(filter: { approval_status: { eq: "APPROVED" } }) {
      nodes {
        id
        slug
        address_line_1
        address_line_2
        city
        clinic_name
        country
        designation
        first_name
        middle_name
        last_name
        is_in_mpm
        is_in_presidents_council
        is_in_corrona_registry
        has_agreed_to_attestation
        payment_methods
        phone
        specialties
        state
        treatments
        url
        zip
        clinical_trials
        sex
        languages_spoken
        telehealth
        latitude
        longitude
      }
    }
  }
`

const getSortedHcps = hcps => {
  const categories = {
    committedToClear: [],
    presCouncil: [],
    proMembers: [],
    corronas: [],
    remainder: [],
  }
  hcps.forEach(hcp => {
    if (hcp.has_agreed_to_attestation) {
      categories.committedToClear.push(hcp)
    } else if (hcp.is_in_presidents_council) {
      categories.presCouncil.push(hcp)
    } else if (hcp.is_in_mpm) {
      categories.proMembers.push(hcp)
    } else if (hcp.is_in_corrona_registry) {
      categories.corronas.push(hcp)
    } else {
      categories.remainder.push(hcp)
    }
  })

  const sortByLastName = (a, b) => (a.last_name < b.last_name ? -1 : 1)

  return [
    ...categories.committedToClear.sort(sortByLastName),
    ...categories.presCouncil.sort(sortByLastName),
    ...categories.proMembers.sort(sortByLastName),
    ...categories.corronas.sort(sortByLastName),
    ...categories.remainder.sort(sortByLastName),
  ]
}

const HcpdProviderList = ({
  latLon,
  radius,
  additionalFilters,
  searchFilter,
  noResultsMessage,
  paginationChangeAction,
}) => {
  const [firstItemForPage, setFirstItemForPage] = useState(0)
  const [lastItemForPage, setLastItemForPage] = useState(ITEMS_PER_PAGE)

  return (
    <StaticQuery
      query={`${HCPD_PROVIDER_LIST_QUERY}`}
      render={data => {
        const hcps = getSortedHcps(data.allHealthCareProvider.nodes)
          .filter(hcp =>
            latLon && latLon.lat
              ? haversineDistance(
                  [latLon.lat, latLon.lng],
                  [hcp.latitude, hcp.longitude]
                ) <= radius
              : 1
          )
          .filter(hcp => {
            const fullName = `${hcp.first_name.toUpperCase()} ${hcp.last_name.toUpperCase()}`
            return (
              hcp.first_name
                .toUpperCase()
                .startsWith(searchFilter.toUpperCase()) ||
              hcp.last_name
                .toUpperCase()
                .startsWith(searchFilter.toUpperCase()) ||
              fullName.startsWith(searchFilter.toUpperCase()) ||
              hcp.clinic_name
                .toUpperCase()
                .startsWith(searchFilter.toUpperCase())
            )
          })
          .filter(hcp =>
            additionalFilters.every(filter =>
              hcp.specialties
                .concat(
                  hcp.treatments,
                  hcp.clinical_trials,
                  hcp.payment_methods,
                  hcp.languages_spoken,
                  [hcp.sex],
                  hcp.telehealth && ['Telehealth']
                )
                .includes(filter)
            )
          )
        return (
          <RegionContainer>
            {hcps.length <= 0 && noResultsMessage ? (
              <NoResultsMessage as="div">
                <PrismicRichText field={noResultsMessage} />
              </NoResultsMessage>
            ) : (
              <>
                {hcps.slice(firstItemForPage, lastItemForPage).map(node => (
                  <HcpdProviderListItem key={node.id} node={node} />
                ))}
                <PaginationControl
                  itemCount={hcps.length}
                  itemsPerPage={ITEMS_PER_PAGE}
                  setFirstItemForPage={setFirstItemForPage}
                  setLastItemForPage={setLastItemForPage}
                  paginationChangeAction={paginationChangeAction}
                />
              </>
            )}
          </RegionContainer>
        )
      }}
    />
  )
}

export default HcpdProviderList
