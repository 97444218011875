import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import HcpdPageHero from '../components/HealthCareProviderDirectory/HcpdPageHero'
import CtaLink from '../components/ContentBlocks/CtaLink'
import HcpdLandingFilterableList from '../components/HealthCareProviderDirectory/HcpdLandingFilterableList'
import { BottomLineGoogleAdContainer } from '../components/styles/GoogleAdsCommon.styles'
import AdHead from '../components/AdHead'
import AdSlot from '../components/AdSlot'

export const Head = ({ pageContext }) => {
  return (
    <AdHead adsData={pageContext.adsData} targets={pageContext.pageTargets} />
  )
}

export const query = graphql`
  {
    prismicHcpdLandingPage {
      data {
        body_text {
          richText
        }
        facebook_image {
          url
        }
        meta_description
        meta_keywords
        meta_title {
          text
        }
        seo_embed_code {
          text
        }
        title {
          text
          richText
        }
        twitter_image {
          url
        }
        cta_text
        no_matching_providers_text {
          richText
        }
        google_ad_page_bottom {
          document {
            ... on PrismicGoogleAdTag {
              id
              data {
                ad_slot_name
              }
            }
          }
        }
        cta_link {
          url
          uid
          type
          link_type
          id
        }
      }
    }
  }
`

const HcpdLandingPage = ({ data }) => {
  const doc = data.prismicHcpdLandingPage.data
  if (!doc) {
    return null
  }
  const bottomLineAdSlot =
    doc.google_ad_page_bottom?.document?.data?.ad_slot_name
  const twitterImage = doc.twitter_image?.url ? doc.twitter_image : null
  const facebookImage = doc.facebook_image?.url ? doc.facebook_image : null
  const seoTitle = doc.meta_title?.text
    ? doc.meta_title.text
    : doc.title?.text
    ? doc.title.text
    : 'Health Care Provider Directory'
  const seo_embed_code = doc.seo_embed_code?.text ?? null

  return (
    <>
      <SEO
        seo={{
          description: doc.meta_description,
          keywords: doc.meta_keywords,
          title: seoTitle,
          twitter_image: twitterImage,
          facebook_image: facebookImage,
          seo_embed_code: seo_embed_code,
        }}
      />
      <Layout>
        <HcpdPageHero
          title={doc.title?.text ?? 'Health Care Provider Directory'}
          body={doc.body_text}
        >
          <CtaLink text={doc.cta_text} link={doc.cta_link} />
        </HcpdPageHero>
        <HcpdLandingFilterableList
          noResultsMessage={doc.no_matching_providers_text?.richText ?? null}
        />
        {bottomLineAdSlot && (
          <BottomLineGoogleAdContainer>
            <AdSlot divId={`div-${bottomLineAdSlot}`} />
          </BottomLineGoogleAdContainer>
        )}
      </Layout>
    </>
  )
}

export default HcpdLandingPage
